import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Snackbar, Alert, Box, Zoom } from '@mui/material'

import { useBreakpoints, useGlobalUi } from '@/hooks'
import { NotificationTypeEnum } from '@/enums'
import { RootState } from '@/state'

import { ReactComponent as IconLevel } from '@/assets/icons/gamification/icon-level-up.svg'
import { ReactComponent as IconLevelJP } from '@/assets/icons/gamification/icon-level-up-jp.svg'
import { ReactComponent as IconRank } from '@/assets/icons/gamification/icon-rank.svg'

import { useStyles } from './styles'

type Props = {
  children: React.ReactNode
}

export function NotificationSnackbarComponent({ children }: Props) {
  const { notificationSnackbar, handleCloseNotificationSnackbar, modal } =
    useGlobalUi()

  const { open } = notificationSnackbar

  const { client } = useSelector(({ initConfig }: RootState) => initConfig)

  const [isVisible, setIsVisible] = useState(false)

  const { classes } = useStyles()

  const { isMobile } = useBreakpoints()

  const onClose = (_event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    handleCloseNotificationSnackbar()
  }

  useEffect(() => {
    if (open && !isVisible) {
      setTimeout(() => {
        setIsVisible(true)
      }, 700)
    }

    if (!open) {
      setIsVisible(false)
    }
  }, [open]) // eslint-disable-line

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      autoHideDuration={60000}
      open={open}
      onClose={onClose}
      sx={{ top: isMobile ? 8 : '30px !important' }}
    >
      <Box className={classes.alertGroup}>
        <Zoom
          in={isVisible}
          style={{
            transitionDuration: '250ms',
          }}
        >
          <Box className={classes.alertIconWrapper}>
            {modal.type === NotificationTypeEnum.RANK_UP ? (
              <IconRank className={classes.alertIcon} />
            ) : (
              <>
                {client === 'JP' ? (
                  <IconLevelJP className={classes.alertIcon} />
                ) : (
                  <IconLevel className={classes.alertIcon} />
                )}
              </>
            )}
          </Box>
        </Zoom>
        {isVisible && (
          <Zoom
            in={isVisible}
            style={{
              transitionDuration: '250ms',
            }}
          >
            <Alert
              icon={false}
              className={classes.alertContainer}
              onClose={onClose}
            >
              {children}
            </Alert>
          </Zoom>
        )}
      </Box>
    </Snackbar>
  )
}
