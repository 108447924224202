import { GlobalStylesProps, Theme } from '@mui/material'

export const globalStyles = (theme: Theme): GlobalStylesProps => {
  return {
    styles: {
      '*': {
        boxSizing: 'inherit',
        '& .paintIcon': {
          '& g path': {
            fill: theme.palette.primary.main,
          },
        },
      },
      /* WebKit Scrollbar */
      '*::-webkit-scrollbar': {
        WebkitAppearance: 'none',
      },
      '*::-webkit-scrollbar:vertical': {
        width: 4,
      },
      '*::-webkit-scrollbar:horizontal': {
        height: 4,
      },
      '*::-webkit-scrollbar-thumb': {
        background:
          theme.palette.mode === 'dark' ? theme.palette.primary.main : '#888',
        borderRadius: 16,
        opacity: '1',
      },
      '*::-webkit-scrollbar-track': {
        background: theme.palette.mode === 'dark' ? '#888' : '#f1f1f1',
        borderRadius: 16,
        opacity: '0.3',
      },
    },
  }
}
