import { Box } from '@mui/material'
import { MissionCommonModels } from '@/state'
import { MissionBundleItemComponent } from '@/components/common'

import { VariantsComponentsEnum } from '@/enums'

type Props = {
  missionBundles: MissionCommonModels.MissionHistoryBundleDetailsType[]
  showEndedMissions: boolean
  handleSetFullContent: (full: boolean) => void
  handleSetSelectedBundle: (
    bundle: MissionCommonModels.MissionHistoryBundleDetailsType,
  ) => void
}

export const MissionBundlesStandardComponent = ({
  missionBundles,
  showEndedMissions,
  handleSetFullContent,
  handleSetSelectedBundle,
}: Props) => {
  return (
    <>
      {missionBundles?.map((bundle) => {
        return (
          <Box mb={1} key={bundle._id}>
            <MissionBundleItemComponent
              missionBundle={bundle}
              variant={VariantsComponentsEnum.SMALL}
              showProgressBar
              openMissions={() => {
                handleSetFullContent(true)
                handleSetSelectedBundle(bundle)
              }}
              showEndedMissions={showEndedMissions}
            />
          </Box>
        )
      })}
    </>
  )
}
