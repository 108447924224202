import { alpha, typographyClasses } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

export const useAnimationRewardsStyles = makeStyles()((theme) => {
  return {
    rewardMissionWrapper: {
      minHeight: 360,
      marginBottom: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',

      [theme.breakpoints.down(350)]: {
        minHeight: 300,
      },

      '& img': {
        width: 250,
        height: '100%',
        marginBottom: 20,

        [theme.breakpoints.down(350)]: {
          width: 205,
        },
      },

      '& .btnRewards': {
        position: 'absolute',
        bottom: -23,
        left: 0,
        right: 0,
        margin: '0 auto',
        color: theme.palette.text.primary,
        background: 'transparent',
        zIndex: 3,

        [`& .${typographyClasses.body2}`]: {
          fontSize: 18,
          textTransform: 'uppercase',
          fontWeight: 700,
        },
      },
    },

    rewardMissionCard: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 200,
      zIndex: 2,
    },

    rewardOneAnimation: {
      position: 'absolute',
      width: 'calc(100% - 110px)',
      height: 'calc(100% - 50px)',

      '& .rewardItemOne': {
        position: 'absolute',
        top: 50,
        right: 0,
        left: 0,
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        gap: 15,
      },

      '& .rewardItemIcon svg': {
        width: 80,
        height: 80,
      },

      '& .rewardItemDescription': {
        [`& .${typographyClasses.body2}`]: {
          fontSize: 16,
          fontWeight: 700,
          color: theme.palette.common.white,
          textTransform: 'uppercase',
        },

        [`& .${typographyClasses.caption}`]: {
          fontSize: 12,
          textTransform: 'uppercase',
          color: theme.palette.common.white,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 5,

          '& svg': {
            fontSize: 14,
          },
        },
      },
    },

    rewardListAnimation: {
      position: 'absolute',
      width: '100%',
      height: 'calc(100% - 63px)',
      overflow: 'auto',
      top: 25,

      [theme.breakpoints.down(350)]: {
        width: '83%',
        top: 20,
      },

      '& .rewardItemList': {
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'between',
        alignItems: 'center',
        gap: 10,
        backgroundColor: alpha(theme.palette.common.white, 0.05),
        borderRadius: 12,
        padding: '6px 8px',
        marginBottom: 8,
      },

      '& .rewardItemIcon svg': {
        width: 40,
        height: 40,
      },

      '& .rewardItemDescription': {
        [`& .${typographyClasses.body2}`]: {
          fontSize: 12,
          fontWeight: 700,
          color: theme.palette.common.white,
        },

        [`& .${typographyClasses.caption}`]: {
          fontSize: 12,
          textTransform: 'capitalize',
          color: theme.palette.common.white,
          display: 'flex',
          alignItems: 'center',
          gap: 2,

          '& svg': {
            fontSize: 14,
          },
        },
      },
    },
  }
})
