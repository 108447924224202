import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => {
  return {
    alertGroup: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
    },
    alertContainer: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      fontWeight: 700,
      width: '100%',
      display: 'flex',
      '& .GamanzaAlert-message': {
        width: '100%',
        padding: 0,
      },
    },

    alertIconWrapper: {
      position: 'absolute',
      display: 'flex',
      zIndex: 100,
      left: 0,
      width: '70px',
      height: '65px',
      [theme.breakpoints.up('sm')]: {
        left: -70,
        width: '145px',
        height: '115px',
      },

      '& svg g path': {
        fill: theme.palette.primary.main,
      },
    },
    alertIcon: {
      [theme.breakpoints.up('sm')]: {
        width: '145px',
        height: '115px',
      },
      [theme.breakpoints.down('sm')]: {
        width: '70px',
        height: '65px',
      },
    },
  }
})
