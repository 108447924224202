import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'

import { MissionCommonModels, RootState } from '@/state'
import { MissionStatusEnum } from '@/enums'
import { ProgressBarComponent } from '@/components/common/progress-bar'

import { MissionColors } from '@/constants'
import { useDialogObjectivesStyles } from './objetives.style'
import { useDialogMissionObjectives } from '../../hooks'

type Props = {
  objectives: MissionCommonModels.ObjectiveMissionTypes[] // TODO: This type will change in the future
  status: MissionStatusEnum
}

export const DialogMissionObjectivesComponent = ({
  objectives,
  status,
}: Props) => {
  const { t } = useTranslation()

  const { baseUrl, client } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const { getObjectiveName } = useDialogMissionObjectives()

  const { classes } = useDialogObjectivesStyles()

  const getIcon = (progress: number) => {
    if (progress === 100) {
      return (
        <img
          src={`${baseUrl}assets/icons/missions/icon-objective-completed.svg`}
          alt="Objective Completed"
        />
      )
    }

    if (status === MissionStatusEnum.ACTIVE) {
      return (
        <img
          src={`${baseUrl}assets/icons/missions/icon-objective-active.svg`}
          alt="Objective Active"
        />
      )
    }

    return (
      <img
        src={`${baseUrl}assets/icons/missions/icon-objective-locked.svg`}
        alt="Objective Locked"
      />
    )
  }

  return (
    <Box className={classes.dialogObjetivesWrapper}>
      <Box className={classes.dialogObjectivesContent}>
        <Typography fontStyle={client === 'JP' ? 'italic' : 'normal'}>
          {t('objectives')}
        </Typography>
        {objectives?.map((objective) => {
          return (
            <Box className={classes.dialogObjetive} key={objective._id}>
              {getIcon(Number(objective.state?.progress))}
              <Box className="objectiveDescription">
                <Typography variant="body2">
                  {getObjectiveName(objective)}
                </Typography>
                {Number(objective.state?.progress) !== 100 && (
                  <ProgressBarComponent
                    progressValue={Number(objective.state?.progress)}
                    balanceText={`${objective.state?.completed || 0}/${
                      objective.state?.total || 0
                    }`}
                    progressColor={
                      status === MissionStatusEnum.ACTIVE
                        ? MissionColors.DIALOG_MISSION.progressValue
                        : MissionColors.DIALOG_MISSION.progressValueInactive
                    }
                    heightProgress={20}
                  />
                )}
              </Box>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
