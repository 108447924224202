import { useDispatch } from 'react-redux'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'

import storage from 'redux-persist/lib/storage'
import { rewardShopSlice } from './reward-shop'
import { initConfigSlice } from './init-config'

import { assetsSlice } from './assets'
import { playerSlice } from './player'
import { globalUiSlice } from './global-ui'

import { orderSlice } from './order'
import { myBoosterSlice } from './my-booster'
import { missionHistorySlice } from './mission-history'

import { globalSettingsSlice } from './global-settings'
import { onSiteSlice } from './on-site'
import { myRewardsSlice } from './my-rewards'

const initPersistConfig = {
  key: 'initConfig',
  storage,
  keyPrefix: 'gamanzaengage_persist_',
}

const playerPersistConfig = {
  key: 'player',
  storage,
  keyPrefix: 'gamanzaengage_persist_',
}

const globalSettingsPersistConfig = {
  key: 'globalSettings',
  storage,
  keyPrefix: 'gamanzaengage_persist_',
}

const rootReducer = combineReducers({
  rewardShop: rewardShopSlice.reducer,
  initConfig: persistReducer(initPersistConfig, initConfigSlice.reducer),
  assets: assetsSlice.reducer,
  player: persistReducer(playerPersistConfig, playerSlice.reducer),
  globalSettings: persistReducer(
    globalSettingsPersistConfig,
    globalSettingsSlice.reducer,
  ),
  globalUI: globalUiSlice.reducer,
  orders: orderSlice.reducer,
  myBooster: myBoosterSlice.reducer,
  missionHistory: missionHistorySlice.reducer,
  onSite: onSiteSlice.reducer,
  myRewards: myRewardsSlice.reducer,
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
    }),
  preloadedState: {},
  devTools: true,
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch

export const persistor = persistStore(store)

export * from './reward-shop'
export * from './assets'
export * from './init-config'
export * from './player'
export * from './global-settings'
export * from './global-ui'
export * from './order'
export * from './endpoints'
export * from './my-booster'
export * from './subscriptions'
export * from './mission-history'
export * from './on-site'
export * from './my-rewards'

export * as GeneralModels from './models'
