import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MissionStatusEnum } from '@/enums'
import {
  claimMultipleRewardsExtraReducer,
  getActiveBundlesExtraReducer,
  getActiveMissionsExtraReducer,
  getExpiredBundlesExtraReducer,
  getExpiredMissionsExtraReducer,
} from './'

import { MissionHistoryModels, MissionCommonModels } from '../'

export const missionHistoryInitialState: MissionHistoryModels.MissionHistoryState =
  {
    isLoading: false,
    missionActiveBundles: null,
    missionsActive: [],
    missionsExpired: null,
    missionExpiredBundles: null,
    reloadBundles: false,
  }

const getUpdatedMissionObjectives = (
  stateMissions:
    | MissionCommonModels.MissionsByBundleType[]
    | MissionCommonModels.MissionType[],
  objective: MissionHistoryModels.SocketObjectiveType,
) => {
  return stateMissions.map(
    (
      mission:
        | MissionCommonModels.MissionsByBundleType
        | MissionCommonModels.MissionType,
    ) => {
      const objectives = mission.objectives.map((itemObjetive) =>
        itemObjetive.originalId === objective.objectiveId &&
        itemObjetive.missionId === objective.missionId
          ? {
              ...itemObjetive,
              state: objective.objectiveSummary.state,
              value: objective.objectiveSummary.value,
              completed: objective.objectiveSummary.completed,
            }
          : itemObjetive,
      )

      return {
        ...mission,
        objectives,
      }
    },
  )
}

const getUpdatedMission = (
  stateMissions:
    | MissionCommonModels.MissionsByBundleType[]
    | MissionCommonModels.MissionType[],
  mission: MissionHistoryModels.SocketMissionType,
) => {
  return stateMissions.map(
    (
      itemMission:
        | MissionCommonModels.MissionsByBundleType
        | MissionCommonModels.MissionType,
    ) =>
      itemMission._id === mission._id
        ? {
            ...itemMission,
            state: mission.state,
            status: mission.status,
            rewardCategories:
              mission.rewardCategories || itemMission.rewardCategories,
            rewardClaimStatus:
              mission.rewardClaimStatus || itemMission.rewardClaimStatus,
          }
        : itemMission,
  )
}

const claimListMission = (
  missions:
    | MissionCommonModels.MissionType[]
    | MissionCommonModels.MissionsByBundleType[],
  missionId: string,
) => {
  return missions?.map((mission) => {
    if (mission._id === missionId) {
      const newMission = {
        ...mission,
        rewardClaimStatus: {
          ...mission.rewardClaimStatus,
          status: 'claimed',
        },
      }
      return newMission
    } else {
      return mission
    }
  })
}

const claimListBundles = (
  bundles: MissionCommonModels.MissionHistoryBundleDetailsType[],
  bundleId: string,
  missionId: string,
) => {
  return bundles.map((bundle) => {
    if (bundle._id === bundleId) {
      const missionIds = claimListMission(bundle.missionIds, missionId)
      return {
        ...bundle,
        missionIds: missionIds,
      }
    } else {
      return bundle
    }
  })
}

export const missionHistorySlice = createSlice({
  name: 'missionHistory',
  initialState: missionHistoryInitialState,
  reducers: {
    updateMissionObjective(
      state,
      action: PayloadAction<MissionHistoryModels.SocketUpdateObjectiveResponse>,
    ) {
      const objective = action.payload.payload

      const newMissionsActive = getUpdatedMissionObjectives(
        state.missionsActive,
        objective,
      )

      const newMissionActiveBundles = state.missionActiveBundles?.map(
        (bundle) => {
          const bundleMissionsUpdated = getUpdatedMissionObjectives(
            bundle.missionIds,
            objective,
          )
          return { ...bundle, missionIds: bundleMissionsUpdated }
        },
      )

      state.missionsActive =
        newMissionsActive as MissionCommonModels.MissionsByBundleType[]
      state.missionActiveBundles =
        newMissionActiveBundles || state.missionActiveBundles
    },
    updateMission(
      state,
      action: PayloadAction<MissionHistoryModels.SocketUpdateMissionResponse>,
    ) {
      const mission = action.payload.payload

      const newMissionActiveBundles = (
        mission.status !== MissionStatusEnum.ENDED
          ? state.missionActiveBundles?.map((bundle) => {
              if (bundle._id === mission.missionBundleId) {
                const bundleMissionsUpdated = getUpdatedMission(
                  bundle.missionIds,
                  mission,
                )
                return { ...bundle, missionIds: bundleMissionsUpdated }
              }

              return bundle
            })
          : state.missionActiveBundles?.filter(
              (itemMission) => itemMission._id !== mission.missionId,
            )
      ) as MissionCommonModels.MissionHistoryBundleDetailsType[]

      state.missionActiveBundles =
        newMissionActiveBundles || state.missionActiveBundles

      state.missionsActive = (
        getUpdatedMission(
          state.missionsActive,
          mission,
        ) as MissionCommonModels.MissionsByBundleType[]
      ).filter((mission) => mission.status !== MissionStatusEnum.COMPLETED)
    },
    updateBundleState(
      state,
      action: PayloadAction<MissionHistoryModels.SocketUpdateMissionResponse>,
    ) {
      const data = action.payload.payload

      state.missionActiveBundles =
        state.missionActiveBundles?.map((bundle) => {
          if (bundle._id === data.missionBundleId) {
            return {
              ...bundle,
              state: data.missionBundleState,
            }
          } else {
            return bundle
          }
        }) || state.missionActiveBundles
    },
    updateBundleClaimMissionReward(
      state,
      action: PayloadAction<MissionHistoryModels.ClaimMissionRewardsPayload>,
    ) {
      const newMissionActiveBundles =
        state.missionActiveBundles?.length > 0
          ? claimListBundles(
              state.missionActiveBundles,
              action.payload.bundleStateId,
              action.payload.missionStateId,
            )
          : state.missionActiveBundles

      const newMissionExpiredBundles =
        state.missionExpiredBundles?.length > 0
          ? claimListBundles(
              state.missionExpiredBundles,
              action.payload.bundleStateId,
              action.payload.missionStateId,
            )
          : state.missionExpiredBundles

      const newMissionsExpired =
        state.missionsExpired?.length > 0
          ? claimListMission(
              state.missionsExpired,
              action.payload.missionStateId,
            )
          : state.missionsExpired

      state.missionActiveBundles = newMissionActiveBundles
      state.missionExpiredBundles = newMissionExpiredBundles
      state.missionsExpired = newMissionsExpired
    },
    updateReloadBundles(state, action: PayloadAction<boolean>) {
      state.reloadBundles = action.payload
    },
  },
  extraReducers: (builder) => {
    claimMultipleRewardsExtraReducer(builder)
    getActiveBundlesExtraReducer(builder)
    getActiveMissionsExtraReducer(builder)
    getExpiredMissionsExtraReducer(builder)
    getExpiredBundlesExtraReducer(builder)
  },
})

export const MissionHistoryActions = missionHistorySlice.actions
