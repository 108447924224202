import { makeStyles } from 'tss-react/mui'

type Props = {
  missionInOrder?: boolean
}

export const useMissionStyles = makeStyles<Props>()((
  theme,
  { missionInOrder },
) => {
  return {
    missionWrapper: {
      display: 'flex',
      flexDirection: 'column',
      animation: 'flexAnimationOpacity 0.35s ease-in forwards',
      height: '100%',

      '@keyframes flexAnimationOpacity': {
        '0%': {
          opacity: 0,
        },

        '100%': {
          opacity: 1,
        },
      },
    },

    missionContent: {
      width: '100%',
      marginTop: 8,
      height: '100%',
      overflow: 'auto',
      padding: 16,
    },

    listMissions: {
      display: 'flex',
      gap: 8,
      justifyContent: 'center',
      flexWrap: 'wrap',

      '& .titleMissionAnyOrder': {
        fontWeight: 600,
        width: '100%',
        marginBottom: 10,
      },
    },
  }
})
