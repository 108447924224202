import { useTranslation } from 'react-i18next'
import { Box, Button, Chip } from '@mui/material'
import { AccessTime, History } from '@mui/icons-material'

import { MissionTabsEnum, SidebarMenuItemType } from '@/enums'
import {
  DialogMissionComponent,
  HeaderNavigationComponent,
} from '@/components/common'
import { useMissionsCustom } from '@/components/sidebar/hooks'

import { useMissionStyles } from './style'
import { MissionBundlesCustomComponent } from './mission-bundles.component'
import { MissionHistoryComponent } from './mission-history.component'

type Props = {
  handleChangeMenuItem: (sidebarMenuItem: string) => void
}

export const SidebarMissionCustomComponent = ({
  handleChangeMenuItem,
}: Props) => {
  const { t } = useTranslation()

  const { classes } = useMissionStyles()

  const { missionsExpired, missionTab, setMissionTab } = useMissionsCustom()

  return (
    <>
      <Box className={classes.missionWrapper}>
        <Box px={2} pt={2}>
          <HeaderNavigationComponent
            title={
              missionTab === MissionTabsEnum.MISSIONS
                ? t('missions')
                : t('missionsHistory')
            }
            titleSxProps={{ textTransform: 'uppercase', fontStyle: 'italic' }}
            goBack={() => {
              missionTab === MissionTabsEnum.MISSIONS
                ? handleChangeMenuItem(SidebarMenuItemType.HOME)
                : setMissionTab(MissionTabsEnum.MISSIONS)
            }}
            interaction={
              <>
                {missionTab === MissionTabsEnum.MISSIONS && (
                  <Button
                    startIcon={<History />}
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      setMissionTab(MissionTabsEnum.HISTORY)
                    }}
                  >
                    {t('history')}
                  </Button>
                )}
                {missionTab === MissionTabsEnum.HISTORY &&
                  missionsExpired?.length > 0 && (
                    <Chip
                      label={t('lastNumberDays', { number: 30 })}
                      icon={<AccessTime sx={{ fontSize: 16 }} />}
                      sx={{
                        background: 'none',
                        fontWeight: 600,
                        '& .GamanzaChip-label': {
                          paddingRight: 0,
                        },
                      }}
                    />
                  )}
              </>
            }
          />
        </Box>
        <Box className={classes.missionContent}>
          {missionTab === MissionTabsEnum.MISSIONS ? (
            <MissionBundlesCustomComponent
              handleChangeMenuItem={handleChangeMenuItem}
            />
          ) : (
            <MissionHistoryComponent
              handleChangeMenuItem={handleChangeMenuItem}
            />
          )}
        </Box>
      </Box>
      <DialogMissionComponent />
    </>
  )
}
