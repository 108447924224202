import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Button, IconButton, Typography } from '@mui/material'

import { ArrowForward } from '@mui/icons-material'
import { GeneralModels, RootState } from '@/state'
import { ChestTypeEnum, UIDialogsEnum, VariantsComponentsEnum } from '@/enums'

import { useBreakpoints, useGlobalUi } from '@/hooks'
import { useStyles } from './style'

type Props = {
  rewards: GeneralModels.RewardCategoryType[]
  chestType: ChestTypeEnum
  missionId?: string
  bundleId?: string
  rankId?: string
  levelId?: string
  amountChest?: number
  variant?: VariantsComponentsEnum
  title?: string
  buttonColor?: string
}

export const ClaimChestItemComponent = ({
  rewards,
  chestType,
  amountChest,
  variant = VariantsComponentsEnum.LARGE,
  missionId,
  bundleId,
  rankId,
  levelId,
  title,
  buttonColor,
}: Props) => {
  const { t } = useTranslation()

  const { baseUrl } = useSelector(({ initConfig }: RootState) => initConfig)

  const { handleOpenDialog, handleWaitCloseDialog, handleCloseModal } =
    useGlobalUi()

  const { isXsMobile } = useBreakpoints()

  const { classes } = useStyles({ variant, buttonColor })

  const handleOnClick = () => {
    handleWaitCloseDialog()
    if (chestType !== ChestTypeEnum.MISSION) {
      handleCloseModal()
    }

    setTimeout(() => {
      handleOpenDialog({
        id: UIDialogsEnum.CHEST_REWARDS,
        object: {
          rewards,
          missionId,
          bundleId,
          chestType,
          rankId,
          levelId,
        },
      })
    }, 500)
  }

  const getIconChestClosed = () => {
    if (chestType === ChestTypeEnum.MISSION) {
      return 'assets/icons/missions/icon-mission-chest-closed.png'
    }
    if (chestType === ChestTypeEnum.LEVEL) {
      return 'assets/icons/gamification/icon-level-chest-closed.png'
    }
    return 'assets/icons/gamification/icon-rank-chest-closed.png'
  }

  return (
    <Box className={classes.claimChestItem}>
      <Box className="claimChestIcon">
        <img
          className="image"
          src={`${baseUrl}${getIconChestClosed()}`}
          alt="Chest Closed"
        />
        {!!amountChest && (
          <Box className="amountRewards">
            <Typography variant="caption">{amountChest}</Typography>
          </Box>
        )}
      </Box>
      <Box className="claimChestText">
        {!!title && <Typography textTransform="uppercase">{title}</Typography>}
        <Typography variant="body2">{t('wonChest')}</Typography>
      </Box>
      {variant === VariantsComponentsEnum.SMALL || isXsMobile ? (
        <IconButton size="small" onClick={handleOnClick}>
          <ArrowForward fontSize="inherit" />
        </IconButton>
      ) : (
        <Button variant="text" onClick={handleOnClick}>
          {t('claim')}
        </Button>
      )}
    </Box>
  )
}
