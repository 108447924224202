import { keyframes } from 'tss-react'
import { makeStyles } from 'tss-react/mui'
import { darken, iconButtonClasses, typographyClasses } from '@mui/material'

import { NotificationTypeEnum } from '@/enums'

interface Props {
  snackbarType?: NotificationTypeEnum
}

export const useStyles = makeStyles<Props>()((theme, { snackbarType }) => {
  const flagAnimation = keyframes`
  from, to {
    transform: scale(1, 1); 
  }
  25% {
    transform: scale(0.95, 1.05); 
  }
  50% { 
    transform: scale(1.05, 0.95); 
  }
  75% { 
    transform: scale(0.95, 1.05); 
  }
`

  return {
    modalContent: {
      maxWidth: '100%',
      borderRadius: 16,
      backgroundColor: theme.palette.background.paper,
      [theme.breakpoints.up('sm')]: {
        width: 450,
      },
      [theme.breakpoints.down('sm')]: {
        width: 340,
      },
      [theme.breakpoints.down(360)]: {
        width: 320,
      },
    },
    modalContentInitial: {
      [theme.breakpoints.up('sm')]: {
        height: 700,
      },
      [theme.breakpoints.down('sm')]: {
        height: 570,
      },
    },
    notificationContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: 16,
      alignItems: 'center',
      backgroundColor: theme.palette.background.default,
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 45,
        minWidth: 540,
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 55,
      },
    },
    initialContainerTop: {
      display: 'flex',
      overflow: 'hidden',
      margin: '0 auto',
      position: 'relative',
      width: '100%',
      height: 'calc(100% - 144px)',

      '& svg .primaryColor path': {
        fill: theme.palette.primary.main,
      },
    },
    initialContainerBottom: {
      width: '100%',
      position: 'absolute',
      overflow: 'hidden',
      bottom: 0,
    },
    initialBottom: {
      background: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, ${darken(theme.palette.primary.main, 0.5)} 95%)`,
      borderRadius: '24px 24px 14px 14px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '32px 0',
      [theme.breakpoints.down('sm')]: {
        padding: '24px 0',
      },
    },
    initialCongratulationsTitle: {
      color: theme.palette.common.white,
      fontSize: 32,
      textAlign: 'center',
      marginBottom: 8,
      fontWeight: 700,
      letterSpacing: '1px',
      [theme.breakpoints.down('sm')]: {
        fontSize: 24,
      },
    },
    finalContainerTop: {
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      [theme.breakpoints.up('sm')]: {
        minHeight: 370,
      },
      [theme.breakpoints.down('sm')]: {
        height: 260,
      },
    },
    finalHeader: {
      borderRadius: '14px 14px 32px 32px',
      background: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, ${darken(theme.palette.primary.main, 0.5)} 95%)`,
      height: 162,
      width: '100%',
      position: 'absolute',
      top: 0,

      [`& .${iconButtonClasses.root}`]: {
        position: 'absolute',
        right: 0,
        color: theme.palette.common.white,
        zIndex: 10,
      },

      [theme.breakpoints.down('sm')]: {
        height: 125,
      },
    },
    finalContainerBottom: {
      position: 'relative',
      overflow: 'hidden',
      borderRadius: '0 0 16px 16px',
      [theme.breakpoints.up('sm')]: {
        padding: '0 32px 32px 32px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '0 15px 15px 15px',
      },
    },
    finalAnimation: {
      margin: '0 auto',
      overflow: 'hidden',
      position: 'absolute',
      top: -60,

      '& svg .primaryColor path': {
        fill: theme.palette.primary.main,
      },

      [theme.breakpoints.down('sm')]: {
        top: -50,
      },
    },
    finalTitle: {
      color: theme.palette.primary.main,
      textAlign: 'center',
      fontWeight: 700,
      position: 'absolute',
      bottom: snackbarType === NotificationTypeEnum.LEVEL_UP ? 100 : 40,
      left: 0,
      right: 0,

      [theme.breakpoints.up('sm')]: {
        fontSize: '40px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '32px',
        bottom: snackbarType === NotificationTypeEnum.LEVEL_UP ? 80 : 30,
      },
    },
    finalLevelAnimation: {
      animation: `${flagAnimation} 3s infinite ease`,
      width: '100%',
    },
    finalCongratulations: {
      textAlign: 'center',
      color: theme.palette.text.secondary,
      [theme.breakpoints.up('sm')]: {
        fontSize: '14px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
    },
    counters: {
      textAlign: 'center',
      width: '100%',
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: 8,
      margin: '0px auto',
      padding: '20px 0',
    },
    counterContainer: {
      display: 'flex',
      justifyContent: 'center',

      '& .pointWrapper': {
        boxShadow: `0px 8px 16px 0px rgba(0, 0, 0, 0.10)`,
      },
    },
    rewards: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    rewardList: {
      display: 'flex',
      flexDirection: 'column',
      margin: '8px auto',
      overflow: 'auto',
      borderRadius: '16px',
      border: `1px solid ${theme.palette.grey[300]}`,
      padding: '4px',
      backgroundColor: theme.palette.grey[200],
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        maxHeight: 180,
      },
      [theme.breakpoints.down('sm')]: {
        maxHeight: 155,
      },
    },

    rewardContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 8,
      backgroundColor: theme.palette.grey[100],
      [theme.breakpoints.up('sm')]: {
        borderRadius: '36px',
        margin: '4px',
        padding: '4px',
      },
      [theme.breakpoints.down('sm')]: {
        borderRadius: '16px',
        margin: '2px',
        padding: '2px',
      },

      '& .rewardItemIcon': {
        width: 28,
        height: 28,
        paddingLeft: 5,

        '&  svg': {
          width: 28,
          height: 28,
        },
      },

      '& .rewardItemDescription': {
        [`& .${typographyClasses.body2}`]: {
          fontSize: 12,
          fontWeight: 700,
          color: theme.palette.grey[700],
        },

        [`& .${typographyClasses.caption}`]: {
          fontSize: 12,
          textTransform: 'capitalize',
          color: theme.palette.grey[700],
          display: 'flex',
          alignItems: 'center',
          gap: 2,

          '& svg': {
            fontSize: 14,
          },
        },
      },
    },
    rewardIcons: {
      width: '35px',
      height: '35px',
    },
  }
})
