import { useTranslation } from 'react-i18next'
import { MissionEventTypeEnum, MissionNextLevelTypeEnum } from '@/enums'
import { MissionCommonModels } from '@/state'

export const useDialogMissionObjectives = () => {
  const { t } = useTranslation()

  const getPaymentMethods = (paymentMethods: string[]) => {
    return paymentMethods.length > 0
      ? ` - ${t('paymentMethods')}: ${paymentMethods.join(' ')}`
      : ''
  }

  const getMinimumAmount = (
    minimumAmounts: { minimumAmount: number; currency: string }[],
  ) => {
    return minimumAmounts.length > 0
      ? minimumAmounts
          .map(
            (item) =>
              `- ${t('minimumAmount')}: ${item.minimumAmount} ${item.currency}`,
          )
          .join(' ')
      : ''
  }

  const getObjectiveName = (
    objective: MissionCommonModels.ObjectiveMissionTypes,
  ) => {
    let objectiveType
    let objectiveMessage = `${t(`missionEventType.${objective.missionEventType}`)} `

    switch (objective.missionEventType) {
      case MissionEventTypeEnum.ACCOUNT_VERIFICATION:
        objectiveType =
          objective as MissionCommonModels.ObjectiveMissionOptInAndAccountVerificationType
        objectiveMessage += `${t('channels')} - ${t('channelType.email')}: ${
          objectiveType.channelEmail
        } -  ${t('channelType.sms')}: ${objectiveType.channelSms}`
        break

      case MissionEventTypeEnum.COMPLETED_DEPOSIT:
      case MissionEventTypeEnum.COMPLETED_WITHDRAWAL:
        objectiveType =
          objective as MissionCommonModels.ObjectiveMissionCompletedTransactionType

        objectiveMessage += `${t(`missionConditionType.${objectiveType.conditionType}`)}: ${
          objectiveType.conditionValue
        } ${getPaymentMethods(objectiveType.paymentMethods)} ${getMinimumAmount(
          objectiveType.minimumAmount,
        )}`
        break

      case MissionEventTypeEnum.LEVEL_UP:
        objectiveType =
          objective as MissionCommonModels.ObjectiveMissionLevelUpType
        objectiveMessage += `- ${t(`missionLevelType.${objectiveType.nextLevelType}`)} ${
          objectiveType.nextLevelType !== MissionNextLevelTypeEnum.ANY
            ? `- ${t('value')}: ${objectiveType.nextLevelValue}`
            : ''
        }`
        break

      case MissionEventTypeEnum.OPT_IN:
        objectiveType =
          objective as MissionCommonModels.ObjectiveMissionOptInAndAccountVerificationType
        objectiveMessage += `${t('channels')} - ${t('channelType.email')}: ${
          objectiveType.channelEmail
        } - ${t('channelType.sms')}: ${objectiveType.channelSms} - ${t('channelType.phone')}: ${
          objectiveType.channelPhone
        } - ${t('channelType.post')}: ${objectiveType.channelPost}`
        break

      case MissionEventTypeEnum.REFER_A_FRIEND:
        objectiveType =
          objective as MissionCommonModels.ObjectiveMissionReferAFriendType
        objectiveMessage += `- ${t(`missionConditionType.${objectiveType.conditionType}`)}: ${
          objectiveType.conditionValue
        } - ${t('godsonAccountStatus')}: ${t(
          `godsonAccountStatusType.${objectiveType.godsonAccountStatusType.toLowerCase()}`,
        )}`
        break

      case MissionEventTypeEnum.WAGER:
      case MissionEventTypeEnum.WIN:
        objectiveType =
          objective as MissionCommonModels.ObjectiveMissionWagerAndWinType
        objectiveMessage += `- ${t(`missionConditionType.${objectiveType.conditionType}`)}: ${
          objectiveType.conditionValue
        } ${getMinimumAmount(objectiveType.minimumAmount)}`
        break

      default:
        // eslint-disable-next-line
        objectiveMessage
        break
    }

    return objectiveMessage
  }

  return {
    getObjectiveName,
  }
}
