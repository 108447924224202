import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, Button, Typography } from '@mui/material'

import { ChestAnimationStatusEnum } from '@/enums'
import { RootState } from '@/state'
import { useGlobalUi } from '@/hooks'

import { ChestAnimationsByType } from '@/constants'
import { useAnimationRewardsStyles } from './rewards-animation.style'
import { RewardOneAnimationComponent, useRewardAnimation } from '../../'

export const RewardsAnimationComponent = () => {
  const { t } = useTranslation()

  const { dialog } = useGlobalUi()

  const {
    animationStatus,
    rewardCardRef,
    handleOpeningAnimation,
    handleOpenedAnimation,
  } = useRewardAnimation()

  const { classes } = useAnimationRewardsStyles()

  const { baseUrl } = useSelector(({ initConfig }: RootState) => initConfig)

  const styleProps = (status: ChestAnimationStatusEnum) => {
    return {
      display: animationStatus === status ? 'block' : 'none',
    }
  }

  const animationType = dialog.object?.chestType

  return (
    <>
      {animationType && (
        <Box className={classes.rewardMissionWrapper}>
          <img
            id="chestAppears"
            src={`${baseUrl}${ChestAnimationsByType[animationType].APPEARS}`}
            alt="Chest Appears"
            style={styleProps(ChestAnimationStatusEnum.APPEARS)}
          />

          <img
            src={`${baseUrl}${ChestAnimationsByType[animationType].JUMPING}`}
            alt="Chest Jumping"
            loading="lazy"
            style={{
              ...styleProps(ChestAnimationStatusEnum.JUMPING),
              zIndex: 3,
              cursor: 'pointer',
            }}
            onClick={handleOpeningAnimation}
          />

          <img
            id="chestOpening"
            src={`${baseUrl}${ChestAnimationsByType[animationType].OPENING}`}
            alt="Chest Opening"
            style={styleProps(ChestAnimationStatusEnum.OPENING)}
          />

          <Box
            className={classes.rewardMissionCard}
            ref={rewardCardRef}
            display={
              animationStatus === ChestAnimationStatusEnum.OPENING ||
              animationStatus === ChestAnimationStatusEnum.SHOWING ||
              animationStatus === ChestAnimationStatusEnum.OPENED
                ? 'block'
                : 'none'
            }
            sx={{
              opacity:
                animationStatus === ChestAnimationStatusEnum.OPENED ? 1 : 0,
            }}
          >
            <img
              src={`${baseUrl}${ChestAnimationsByType[animationType].CARD}`}
              alt="Chest Reward Card"
            />
            <RewardOneAnimationComponent
              rewards={dialog.object?.rewards}
              rewardOne={false}
              mainClassName="rewardItemList"
            />
            {animationStatus !== ChestAnimationStatusEnum.OPENED && (
              <RewardOneAnimationComponent
                rewards={dialog.object?.rewards}
                rewardOne={true}
                mainClassName="rewardItemOne"
              />
            )}
          </Box>

          {animationStatus !== ChestAnimationStatusEnum.OPENED && (
            <Button
              className="btnRewards"
              variant="text"
              onClick={() => {
                animationStatus === ChestAnimationStatusEnum.JUMPING
                  ? handleOpeningAnimation()
                  : handleOpenedAnimation()
              }}
              disabled={
                animationStatus === '' ||
                animationStatus === ChestAnimationStatusEnum.APPEARS
              }
            >
              <Typography variant="body2">
                {animationStatus === ChestAnimationStatusEnum.APPEARS ||
                animationStatus === ChestAnimationStatusEnum.JUMPING ||
                animationStatus === ''
                  ? t('clickToOpen')
                  : t('skip')}
              </Typography>
            </Button>
          )}
        </Box>
      )}
    </>
  )
}
