import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Box, Button, Typography } from '@mui/material'
import { MissionItemDenseComponent } from '@/components/common'
import { DialogTypeEnum, SidebarMenuItemType, UIDialogsEnum } from '@/enums'

import { useMissionsActions } from '@/components/sidebar/hooks'
import { RootState } from '@/state'
import { useBreakpoints, useGlobalUi } from '@/hooks'

import { SliderItemsComponent } from '@/components/common'

type Props = {
  sidebarClient: string
  openSidebar: boolean
  handleChangeMenuItem?: (sidebarMenuItem: string) => void
}

export const ActiveMissionsComponent = ({
  sidebarClient,
  openSidebar,
  handleChangeMenuItem,
}: Props) => {
  const { t } = useTranslation()

  const { getActiveMissions } = useMissionsActions()

  const { missionsActive } = useSelector(
    ({ missionHistory }: RootState) => missionHistory,
  )

  useEffect(() => {
    getActiveMissions()
  }, []) // eslint-disable-line

  const handleSeeAll = () => {
    handleChangeMenuItem(SidebarMenuItemType.MISSIONS)
  }

  const { isMobile } = useBreakpoints()

  const amountMobileMission = isMobile && !openSidebar ? 1 : 2

  const { handleOpenDialog } = useGlobalUi()

  const listMissions = openSidebar
    ? missionsActive
    : missionsActive.slice(0, amountMobileMission)

  return (
    <>
      {listMissions?.length > 0 && (
        <Box className="activeItemsWrapper">
          <Box className="activeItemsTitle" justifyContent="space-between">
            <Typography
              className="titleSection"
              fontStyle={sidebarClient === 'JP' ? 'italic' : 'normal'}
            >
              {t('missions')}
            </Typography>
            {openSidebar && (
              <Button variant="text" onClick={() => handleSeeAll()}>
                <Typography variant="caption">{t('seeAll')}</Typography>
              </Button>
            )}
          </Box>
          <SliderItemsComponent
            amountItems={listMissions.length}
            itemsByBlock={3}
            expanded={openSidebar}
            mobileWidth={267}
            gap={17}
            content={
              <>
                {listMissions?.map((mission, index) => {
                  return (
                    <Box
                      key={`slider_${mission._id}`}
                      zIndex={!openSidebar ? missionsActive.length - index : 1}
                      onClick={() =>
                        handleOpenDialog({
                          id: UIDialogsEnum.MISSION_DETAILS,
                          object: {
                            missionId: mission._id,
                            title: t(
                              `timeframeType.${mission.missionBundleSettings.timeFrame.timeFrameType}`,
                            ),
                            type: DialogTypeEnum.ACTIVE_MISSIONS,
                          },
                        })
                      }
                    >
                      <MissionItemDenseComponent
                        status={mission.status}
                        expanded={openSidebar}
                        endDate={mission.endDate}
                        state={mission.state}
                      />
                    </Box>
                  )
                })}
              </>
            }
          />
        </Box>
      )}
    </>
  )
}
