import { useEffect, useRef, useState } from 'react'
import { Box, Slide } from '@mui/material'
import { useStyles } from './styles'

type Props = {
  children: React.ReactElement
  appears: number
}

export const ModalCounterComponent = ({ children, appears }: Props) => {
  const [isVisible, setIsVisible] = useState(false)

  const containerRef = useRef(null)

  const { classes } = useStyles({})

  useEffect(() => {
    const timerIn = setTimeout(() => {
      setIsVisible(true)
    }, appears)

    return () => {
      clearTimeout(timerIn)
    }
  }, []) // eslint-disable-line

  return (
    <Slide
      direction="up"
      in={isVisible}
      container={containerRef.current}
      timeout={1000}
    >
      <Box className={classes.counterContainer}>{children}</Box>
    </Slide>
  )
}
