import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Lottie from 'lottie-react'

import { Box, Slide, Typography, useTheme } from '@mui/material'
import { useGlobalUi, usePlayerData } from '@/hooks'
import { GeneralModels, RootState } from '@/state'

import { NotificationTypeEnum } from '@/enums'
import { useNotificationInitial } from '../hooks'
import { useStyles } from './styles'

type Props = {
  initialAnimationData: GeneralModels.LottieAnimationData
}

export const ModalStatusInitialComponent = ({
  initialAnimationData,
}: Props) => {
  const { t } = useTranslation()

  const { modal } = useGlobalUi()

  const { gamificationPlayer } = useSelector(({ player }: RootState) => player)

  const { playerRankName } = usePlayerData()

  const { isVisible, containerRef } = useNotificationInitial()

  const theme = useTheme()

  const { classes } = useStyles({ snackbarType: modal.type })

  return (
    <>
      <Box className={classes.initialContainerTop}>
        <Lottie animationData={initialAnimationData} loop={false} />
      </Box>
      <Box className={classes.initialContainerBottom}>
        <Slide
          direction="up"
          in={isVisible}
          container={containerRef.current}
          timeout={750}
        >
          <Box className={classes.initialBottom}>
            <Typography className={classes.initialCongratulationsTitle}>
              {t('congratulations')}
            </Typography>
            <Typography
              sx={{ color: theme.palette.common.white }}
              textAlign="center"
            >
              {modal.type === NotificationTypeEnum.RANK_UP
                ? t('congratulationsRankUp', {
                    rank: playerRankName,
                  })
                : t('congratulationsLevelUp', {
                    level: gamificationPlayer.level.levelNumber,
                  })}
            </Typography>
          </Box>
        </Slide>
      </Box>
    </>
  )
}
