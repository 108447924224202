import { makeStyles } from 'tss-react/mui'
import {
  MissionColors,
  MissionItemSize,
  getBorderColorByMode,
} from '@/constants'
import {
  MissionStatusEnum,
  OrientationComponentsEnum,
  VariantsComponentsEnum,
} from '@/enums'

interface Props {
  sidebarClient?: string
  orientation?: OrientationComponentsEnum
  variant?: VariantsComponentsEnum
  status?: MissionStatusEnum
}

export const useMissionItemStyles = makeStyles<Props>()((
  theme,
  { sidebarClient, variant, orientation, status },
) => {
  const iconSize = (variantType: VariantsComponentsEnum) => {
    return orientation === OrientationComponentsEnum.HORIZONTAL
      ? MissionItemSize[VariantsComponentsEnum.SMALL].size
      : MissionItemSize[variantType].size
  }

  const missionStatusSize = () => {
    if (orientation === OrientationComponentsEnum.HORIZONTAL) {
      return 14
    }

    return variant === VariantsComponentsEnum.LARGE ? 16 : 12
  }

  return {
    missionContent: {
      width: '100%',
      height: '100%',
      borderRadius: 12,
      position: 'relative',
      display: 'flex',
      flexDirection:
        orientation === OrientationComponentsEnum.VERTICAL ? 'column' : 'row',

      '& .missionProgress': {
        width: '100%',
        height: '100%',
        position: 'relative',

        '& .missionCircularProgress': {
          color:
            status === MissionStatusEnum.ENDED
              ? MissionColors.STATUS.ENDED.content
              : theme.palette.primary.main,
          border: '1px solid',
          borderRadius: '50%',
        },

        '& circle': {
          strokeLinecap: 'round',
        },
      },

      '& .missionContentIcon': {
        position: 'relative',
        width: '100%',
        height: '100%',
      },

      '& .icon': {
        '& svg, & img': {
          margin: '0 auto',
          width: iconSize(variant),
          height: iconSize(variant),
          display: 'block',

          '& g path': {
            fill:
              status === MissionStatusEnum.ENDED
                ? MissionColors.STATUS.ENDED.content
                : theme.palette.primary.main,
          },

          [theme.breakpoints.down('sm')]: {
            width: iconSize(VariantsComponentsEnum.SMALL),
            height: iconSize(VariantsComponentsEnum.SMALL),
          },
        },
      },

      '& .missionStatus': {
        width: '100%',
        textAlign: 'center',
        fontSize: missionStatusSize(),
        textTransform: 'uppercase',
        fontStyle: sidebarClient === 'JP' ? 'italic' : 'normal',
        zIndex: 2,
        fontWeight: 700,

        [theme.breakpoints.down('sm')]: {
          fontSize: 12,
        },
      },

      '& .missionTimeRemaining': {
        textAlign: 'center',
        fontSize: variant === VariantsComponentsEnum.LARGE ? 10 : 8,
        fontWeight: 600,
        borderRadius: 4,
        paddingLeft: variant === VariantsComponentsEnum.LARGE ? 5 : 2,
        paddingRight: variant === VariantsComponentsEnum.LARGE ? 5 : 2,
        backgroundColor: theme.palette.background.default,
        color:
          theme.palette.mode === 'dark'
            ? theme.palette.text.primary
            : theme.palette.primary.main,
        border: `1px solid ${getBorderColorByMode(theme)}`,
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        width: 'max-content',

        '& svg': {
          fontSize: 12,
        },

        [theme.breakpoints.down('sm')]: {
          fontSize: 8,
          paddingLeft: 2,
          paddingRight: 2,
        },
      },

      '& .missionArrow': {
        backgroundColor: theme.palette.background.default,
        transform: 'translateY(-50%) rotate(45deg)',
        position: 'absolute',
        margin: '0 auto',
      },
    },

    missionContentVertical: {
      '& .missionProgress': {
        '& .missionCircularProgress': {
          position: 'absolute',
          top: 6,
          right: 0,
          left: 0,
          margin: '0 auto',
        },

        '& .icon': {
          position: 'absolute',
          right: 0,
          left: 2.5,
          top: 2.5,
        },
      },

      '& .missionContentIcon .icon': {
        position: 'absolute',
        left: 0,
        right: 0,
        margin: '0 auto',
        top: 3,
      },

      '& .missionTexts': {
        width: '100%',
        height: 30,

        [theme.breakpoints.down('sm')]: {
          height: 32,
        },
      },

      '& .missionTimeRemaining': {
        position: 'absolute',
        bottom: 25,
        left: 0,
        right: 0,
        margin: '0 auto',
      },

      '& .missionExtraIcon': {
        position: 'absolute',
        top: variant === VariantsComponentsEnum.LARGE ? -30 : 0,
        right: variant === VariantsComponentsEnum.LARGE ? -22 : 0,

        '& img': {
          width: variant === VariantsComponentsEnum.LARGE ? 'auto' : 24,
        },

        [theme.breakpoints.down('sm')]: {
          top: 0,
          right: 0,

          '& img': {
            width: 24,
          },
        },
      },

      '& .missionArrow': {
        display: 'block',
        bottom: variant === VariantsComponentsEnum.LARGE ? -45 : -25,
        left: 0,
        right: 0,
        width: variant === VariantsComponentsEnum.LARGE ? 30 : 20,
        height: variant === VariantsComponentsEnum.LARGE ? 30 : 20,

        [theme.breakpoints.down('sm')]: {
          bottom: -25,
          width: 20,
          height: 20,
        },
      },
    },

    missionContentHorizontal: {
      padding: '10px 16px',
      gap: 10,

      '& .missionContentIcon': {
        width: MissionItemSize[VariantsComponentsEnum.SMALL].size,
      },

      '& .missionProgress': {
        width: MissionItemSize[VariantsComponentsEnum.SMALL].size,

        '& .missionCircularProgress': {
          position: 'absolute',
          top: 3,
          left: 2,
          right: 'inherit',
        },

        '& .icon': {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 'inherit',
        },
      },

      '& .missionTexts': {
        width: `calc(100% - ${MissionItemSize[VariantsComponentsEnum.SMALL].size}px)`,
        height: MissionItemSize[VariantsComponentsEnum.SMALL].size,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
      },

      '& .missionStatus': {
        textAlign: 'left',
      },

      '& .missionExtraIcon': {
        position: 'absolute',
        top: 0,
        right: 0,

        '& img': {
          width: 24,
        },
      },

      '& .missionArrow': {
        left: -12,
        top: 35,
        width: 25,
        height: 25,
      },
    },
  }
})
