import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Lottie from 'lottie-react'

import { Box, IconButton, Slide, Typography, useTheme } from '@mui/material'
import { Close } from '@mui/icons-material'
import {
  ChestTypeEnum,
  NotificationTypeEnum,
  VariantsComponentsEnum,
} from '@/enums'

import { GeneralModels, RootState } from '@/state'
import { useGlobalUi, usePlayerData } from '@/hooks'
import { ClaimChestItemComponent } from '@/components/common'

import { ModalCountersComponent, ModalRewardComponent } from './'
import { useNotificationFinal } from '../hooks'
import { useStyles } from './styles'

type Props = {
  finalAnimationData: GeneralModels.LottieAnimationData
}

export const ModalStatusFinalComponent = ({ finalAnimationData }: Props) => {
  const { t } = useTranslation()

  const { modal, handleCloseModal } = useGlobalUi()

  const { rewardClaimEnable } = useSelector(
    ({ globalSettings }: RootState) => globalSettings,
  )

  const { gamificationPlayer, earnedRewards } = useSelector(
    ({ player }: RootState) => player,
  )

  const { containerRef, isVisibleRewards, level, tokens, rankName, xpBalance } =
    useNotificationFinal()

  const { playerRankName } = usePlayerData()

  const { classes } = useStyles({ snackbarType: modal.type })

  const theme = useTheme()

  return (
    <>
      <Box className={classes.finalContainerTop}>
        <Slide
          direction="down"
          in={true}
          container={containerRef.current}
          timeout={750}
        >
          <Box className={classes.finalHeader}>
            <IconButton onClick={handleCloseModal}>
              <Close />
            </IconButton>
          </Box>
        </Slide>
        <Box className={classes.finalAnimation}>
          <Lottie animationData={finalAnimationData} loop />
          <Box>
            {modal.type === NotificationTypeEnum.LEVEL_UP && (
              <Typography
                className={`${classes.finalTitle} ${classes.finalLevelAnimation}`}
              >
                {gamificationPlayer.level.levelNumber}
              </Typography>
            )}
            {modal.type === NotificationTypeEnum.RANK_UP && (
              <Typography className={classes.finalTitle}>
                {playerRankName}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      <Box className={classes.finalContainerBottom}>
        <Box mt={2}>
          <Typography className={classes.finalCongratulations}>
            {t(
              earnedRewards?.length > 0
                ? 'congratulationsRewards'
                : 'congratulationsWithoutRewards',
              {
                type:
                  modal.type === NotificationTypeEnum.RANK_UP
                    ? playerRankName
                    : `${t('level')} ${gamificationPlayer.level.levelNumber}`,
              },
            )}
          </Typography>
        </Box>
        <ModalCountersComponent
          level={level}
          rankName={rankName}
          tokens={tokens}
          xpBalance={xpBalance}
        />
        {earnedRewards?.length > 0 && (
          <Box className={classes.rewards}>
            <Slide
              direction="up"
              in={isVisibleRewards}
              container={containerRef.current}
              timeout={2000}
            >
              <Box width="100%">
                {rewardClaimEnable ? (
                  <ClaimChestItemComponent
                    rewards={earnedRewards}
                    variant={VariantsComponentsEnum.LARGE}
                    chestType={
                      modal.type === NotificationTypeEnum.LEVEL_UP
                        ? ChestTypeEnum.LEVEL
                        : ChestTypeEnum.RANK
                    }
                    title={
                      modal.type === NotificationTypeEnum.LEVEL_UP
                        ? t('levelReward')
                        : t('rankReward')
                    }
                    buttonColor={theme.palette.primary.main}
                    rankId={gamificationPlayer.rank.id}
                    levelId={gamificationPlayer.level.id}
                  />
                ) : (
                  <Box className={classes.rewardList}>
                    {earnedRewards.map(
                      (reward: GeneralModels.RewardCategoryType) => (
                        <ModalRewardComponent reward={reward} key={reward.id} />
                      ),
                    )}
                  </Box>
                )}
              </Box>
            </Slide>
          </Box>
        )}
      </Box>
    </>
  )
}
